<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Jobs" to="/jobs/list/1" />
      <q-breadcrumbs-el label="Countries" to="/jobs/country" />
      <q-breadcrumbs-el :label="siteArrays && siteArrays.countries[$route.params.countryCode]" />
    </q-breadcrumbs>

  <div class="row justify-between items-end q-mb-sm q-mt-md">
    <div class="col full-height text-h5">
      Total {{ $route.params.countryCode }} Jobs: {{numRecs}} <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}} Jobs
      </span>
    </div>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>
  </div>

  <table class="table table-striped table-sm full-width">
    <job-list-item v-for="post in recs" :key="post._id" :post="post" view-route-name="jobCountryView"></job-list-item>
  </table>
</div>
</template>

<script>
import jobModule from "../../../store";
import storeRecListMixin from "src/mixins/store_rec_list.mixin";
import { mapState, mapGetters } from 'vuex';

export default {
  mixins: [
    storeRecListMixin,
  ],
  data() {
    return {
      collection: 'posts',
      vuexCollection: 'job.recs',
      vuexPath: 'job',
      vuexPageRequestAction: jobModule.actionTypes.LoadRecordsPageRequest,

      qry: { 'location.country_code': this.$route.params.countryCode },
    }
  },
  computed: {
      ...mapState('site', {
        siteArrays: 'arrs',
      }),

  },

  watch: {
  },

  created () {
      this.qry = { 'location.country_code': this.$route.params.countryCode };
  },

  methods: {
    bookmark (postId) {
      console.log('bookmark post', postId);
    },

    gotoPage (pageId) {
      this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadRecordsPageRequest, { page_id: +pageId,  qry: { 'location.country_code': this.$route.params.countryCode } } );

      this.$router.push({ params: { pageId }});
    },
    reqData (force) {
      console.log('skills reqData');
      if (! this.hasPageSet(this.$route.params.pageId, this.qry) ||  force ) {
        const payload = {
          page_id: +this.$route.params.pageId,
          qry: { 'location.country_code': this.$route.params.countryCode }
        };

        this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadRecordsPageRequest, payload);
      }
    },

  },
}
</script>



